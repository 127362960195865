import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";

Sentry.init({
  dsn: "https://a9abbef7387083b228c5a88c86c3cdd2@o255762.ingest.us.sentry.io/4507809531101184",
  // @ts-ignore
  release: PKG.version,
  tracesSampleRate: 1.0
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
